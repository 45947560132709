import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BillRunStatusService {
  private readonly httpClient = inject(HttpClient);

  getBillRunStatus(): Observable<boolean> {
    const url = 'api/v1/payment-management/bill-run/active';

    return this.httpClient.get<boolean>(environment.portalApiUrl + url);
  }
}
