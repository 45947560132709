<itc-custom-modal [isCloseButtonVisible]="false">
  <div header>
    <div class="session-timeout__header">
      <div>{{ 'session-timeout.title' | translate }}</div>
      <itc-countdown [seconds]="getLogoutDelayInSeconds()" />
    </div>
  </div>
  <div main>
    <div class="session-timeout__container">
      <div class="session-timeout__description">{{ 'session-timeout.description' | translate }}</div>
      <div class="session-timeout__buttons">
        <kds-regular-button
          [testId]="testIds.logoutButton"
          [buttonText]="'session-timeout.logout' | translate"
          [colorScheme]="'secondary'"
          (buttonClick)="logOut()"
        />
        <kds-regular-button
          [testId]="testIds.resumeSessionButton"
          [buttonText]="'session-timeout.resume' | translate"
          (buttonClick)="resumeSession()"
        />
      </div>
    </div>
  </div>
</itc-custom-modal>
