import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { iconBasePath } from '@layout/left-navigation/model/icon-paths';
import { Icons } from '@layout/left-navigation/model/icons.enum';
import { LeftNavigationTestIds } from '@layout/left-navigation/components/left-navigation/left-navigation.test-ids';
import { SsoTargetPage } from '@design/open-sso-link/models/sso-target-page.enum';
import { Permission } from '@core/permissions/models/permission.enum';
import { IExternalMenuItem, IMenuItem } from '@layout/left-navigation/model/menu-item.interface';
import { of } from 'rxjs';
import { Country } from '@core/locations/models/country.enum';
import { VanillaForumsFacade } from '@layout/left-navigation/facades/vanilla-forums.facade';
import { AbsorbFacade } from '@layout/left-navigation/facades/absorb.facade';
import { NavigationLinkComponent } from '@layout/left-navigation/components/navigation-link/navigation-link.component';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '@core/permissions/permissions.module';
import { NavigationTaggedLinkComponent } from '@layout/left-navigation/components/navigation-tagged-link/navigation-tagged-link.component';
import { AsyncPipe } from '@angular/common';
import { NavigationExternalLinkComponent } from '@layout/left-navigation/components/navigation-external-link/navigation-external-link.component';
import { LocationsModule } from '@core/locations/locations.module';
import { FeaturesDirective } from '@core/features/directives/features.directive';
import { Feature } from '@core/features/models/feature.enum';
import { K365SetupGuidesAccessDirective } from '@layout/k365-setup-guides-access/directives/k365-setup-guides-access.directive';
import { PartnerProgramActiveFacade } from '@layout/partner-program-active/facades/partner-program-active.facade';
import { PaymentManagementActiveFacade } from '@layout/payment-management-active/facades/payment-management-active.facade';
import { PermissionsFacade } from '@core/permissions/facades/permissions.facade';
import { FeaturesFacade } from '@core/features/facades/features.facade';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'itc-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NavigationLinkComponent,
    TranslateModule,
    PermissionsModule,
    NavigationTaggedLinkComponent,
    AsyncPipe,
    NavigationExternalLinkComponent,
    LocationsModule,
    FeaturesDirective,
    K365SetupGuidesAccessDirective,
  ],
})
export class LeftNavigationComponent implements OnInit {
  @Input({ required: true }) isCollapsed: boolean;

  readonly Feature = Feature;
  readonly Permission = Permission;
  readonly TestIds = LeftNavigationTestIds;
  readonly home = {
    id: 'home',
    routerLink: 'home',
    iconName: Icons.home,
  };
  readonly executiveReports = {
    id: 'executive-reports',
    routerLink: 'executive-reports',
    iconName: Icons.ExecutiveReports,
    permission: Permission.ExecutiveReports,
  };
  readonly viewAndPayInvoices: IExternalMenuItem = {
    id: 'view-and-pay-invoices',
    iconName: Icons.billing,
    permission: Permission.BillingAndSubscriptions,
    ssoTargetPage: SsoTargetPage.billing,
  };
  readonly paymentManagement: IMenuItem = {
    id: 'payment-management',
    iconName: Icons.paymentManagement,
    permission: Permission.PaymentManagementView,
    routerLink: 'payment-management',
  };
  readonly paymentMethods: IExternalMenuItem = {
    id: 'payment-methods',
    iconName: Icons.billing,
    permission: Permission.BillingAndSubscriptions,
    ssoTargetPage: SsoTargetPage.paymentMethods,
  };
  readonly subscriptions: IMenuItem = {
    id: 'subscriptions',
    routerLink: 'subscriptions',
    permission: Permission.BillingAndSubscriptions,
    iconName: Icons.subscriptions,
  };
  readonly internalMenuItems: IMenuItem[] = [
    {
      id: 'cooper',
      routerLink: 'cooper',
      permission: Permission.Insights,
      iconName: Icons.insights,
    },
    {
      id: 'support',
      routerLink: 'support',
      iconName: Icons.support,
    },
    {
      id: 'admin-settings',
      routerLink: 'admin-settings',
      permission: Permission.UserManagementView,
      iconName: Icons.settings,
    },
    {
      id: 'automation-center',
      iconName: Icons.AutomationCenter,
      routerLink: 'automation-center',
      permission: Permission.AutomationCenterView,
      feature: Feature.AutomationCenter,
      hasTag: true,
    },
    {
      id: 'cyber-insurance',
      routerLink: 'cyber-insurance',
      iconName: Icons.cyberInsurance,
      permission: Permission.CyberInsurance,
      accessibleCountries: [Country.US, Country.AU, Country.NZ],
    },
  ];
  readonly professionalServices: IMenuItem = {
    id: 'professional-services',
    routerLink: 'professional-services',
    iconName: Icons.ProfessionalServices,
    permission: Permission.ProfessionalServicesDashboard,
  };
  readonly absorb: IExternalMenuItem = {
    id: 'absorb',
    iconName: Icons.kaseyaUniversity,
    externalLink: this.absorbFacade.ssoLoginUri$,
  };
  readonly kaseyaStore: IExternalMenuItem = {
    id: 'kaseya-store',
    permission: Permission.BillingAndSubscriptions,
    iconName: Icons.kaseyaStore,
    ssoTargetPage: SsoTargetPage.kaseyaStore,
  };
  readonly organizationMapping: IMenuItem = {
    id: 'organization-mapping',
    permission: Permission.OrganizationMappingsView,
    iconName: Icons.organizationMapping,
    routerLink: 'organization-mapping',
  };
  readonly kaseya365SetupGuide: IMenuItem = {
    id: 'kaseya-365-setup-guide',
    iconName: Icons.kaseya365Upgrade,
    routerLink: 'kaseya-365-setup-guide',
    permission: Permission.OnboardingSubscriptionsView,
  };
  readonly kaseyaCommunity: IExternalMenuItem = {
    id: 'kaseya-community',
    iconName: Icons.kaseyaCommunity,
    externalLink: this.vanillaForumsFacade.loginUrl$,
  };
  readonly partnerProgram: IMenuItem = {
    id: 'partner-program',
    iconName: Icons.partnerProgram,
    routerLink: 'partner-program',
  };
  readonly kaseyaEvents: IExternalMenuItem = {
    id: 'kaseya-events',
    iconName: Icons.kaseyaEvents,
    externalLink: of('https://events.kaseya.com/'),
  };
  readonly kaseyaSwag: IExternalMenuItem = {
    id: 'kaseya-swag',
    iconName: Icons.kaseyaSwag,
    externalLink: of('https://merch.kaseya.com'),
  };
  readonly isPartnerProgramActive$ = this.partnerProgramActiveFacade.isPartnerProgramActive$;
  readonly isPaymentManagementActive = this.paymentManagementActiveFacade.isPaymentManagementActive;
  readonly isPaymentManagementFeatureEnabled = toSignal<boolean>(this.featuresFacade.isFeatureEnabled$(Feature.PaymentManagement));

  constructor(
    private vanillaForumsFacade: VanillaForumsFacade,
    private absorbFacade: AbsorbFacade,
    private partnerProgramActiveFacade: PartnerProgramActiveFacade,
    private paymentManagementActiveFacade: PaymentManagementActiveFacade,
    private permissionsFacade: PermissionsFacade,
    private featuresFacade: FeaturesFacade
  ) {}

  ngOnInit(): void {
    this.partnerProgramActiveFacade.loadPartnerProgramActive();
    if (this.permissionsFacade.hasAccessToPaymentManagementView) {
      this.paymentManagementActiveFacade.loadPaymentManagementActive();
    }
  }

  getIconPath(iconName: string): string {
    return iconBasePath + iconName;
  }
}
