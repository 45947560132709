@warn "Deprecated, please use font-size instead";

@mixin font-size-small {
  font-size: var(--font-size-small);
  line-height: 14px;
}

@mixin font-size {
  font-size: var(--font-size);
  line-height: 16px;
}

@mixin font-size-medium {
  font-size: var(--font-size-medium);
  line-height: 21px;
}

@mixin font-size-large {
  font-size: var(--font-size-large);
  line-height: 38px;
}
