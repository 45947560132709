@use 'mixins/legacy-font-size';
@use 'kaseya-styles/mixins/icon-color';

.manager {
  color: var(--font-color-dark-blue);

  &__header {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 14px;

    &-text {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }

    &-fullname {
      @include legacy-font-size.font-size;
      font-family: var(--font-family-medium);
    }

    &-job-title {
      font-size: var(--font-size-small);
      line-height: 13px;
    }

    &-fullname,
    &-job-title {
      word-break: normal;
      overflow-wrap: anywhere;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &-section {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    &-icon {
      @include icon-color.dark-blue-icon;
    }

    &-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: var(--font-size-small);
      line-height: 13px;
    }

    &-text--email {
      text-decoration: none;
      color: var(--primary-color);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
