<div class="impersonate-banner__container">
  <div class="impersonate-banner__text">
    <span>{{ 'impersonate.banner.text' | translate }}</span>

    @if (!!userFullName && !!organizationName) {
      <span class="impersonate-banner__text-bold">{{ userFullName }}</span>
      <span>{{ 'impersonate.banner.from' | translate }}</span>
      <span class="impersonate-banner__text-bold">{{ organizationName }}</span>
    } @else {
      <kds-progress-spinner spinnerColor="#333333" [fitContainer]="false" [spinnerSize]="16" />
    }
  </div>

  <div class="impersonate-banner__button" (click)="logOut()">{{ 'impersonate.banner.button' | translate }}</div>
</div>
