@mixin white-icon($opacity: 1) {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(101%) opacity($opacity);
}

@mixin light-gray-icon {
  filter: invert(43%) sepia(20%) saturate(337%) hue-rotate(172deg) brightness(91%) contrast(84%);
}

// #626D7A;
@mixin gray-icon {
  filter: invert(45%) sepia(17%) saturate(343%) hue-rotate(172deg) brightness(89%) contrast(94%);
}

// #999999
@mixin dark-gray-icon {
  filter: invert(58%) sepia(0%) saturate(6637%) hue-rotate(315deg) brightness(111%) contrast(69%);
}

@mixin primary-color-icon {
  filter: invert(34%) sepia(38%) saturate(5970%) hue-rotate(225deg) brightness(101%) contrast(87%);
}

// #41c06d
@mixin light-green-icon {
  filter: invert(67%) sepia(34%) saturate(720%) hue-rotate(88deg) brightness(88%) contrast(94%);
}

@mixin green-icon {
  filter: invert(50%) sepia(37%) saturate(635%) hue-rotate(93deg) brightness(97%) contrast(91%);
}

// #001D30
@mixin dark-blue-icon {
  filter: invert(10%) sepia(32%) saturate(2245%) hue-rotate(170deg) brightness(92%) contrast(104%);
}

// #D22F2D
@mixin red-icon {
  filter: invert(32%) sepia(67%) saturate(4454%) hue-rotate(343deg) brightness(84%) contrast(96%);
}

// #A61C0A
@mixin dark-red-icon {
  filter: invert(17%) sepia(96%) saturate(1433%) hue-rotate(343deg) brightness(110%) contrast(116%);
}

// #5C0F06
@mixin deep-red {
  filter: invert(9%) sepia(36%) saturate(6160%) hue-rotate(355deg) brightness(97%) contrast(99%);
}

// #166EFA
@mixin light-blue-icon {
  filter: invert(29%) sepia(83%) saturate(1990%) hue-rotate(207deg) brightness(98%) contrast(100%);
}

// #333333
@mixin charcoal-gray-icon {
  filter: brightness(0) saturate(100%) invert(8%) sepia(98%) saturate(0%) hue-rotate(194deg) brightness(101%) contrast(76%);
}

// #A5ADB7
@mixin disabled-gray-icon {
  filter: invert(70%) sepia(11%) saturate(244%) hue-rotate(173deg) brightness(97%) contrast(91%);
}

// #893DE7
@mixin purple-icon {
  filter: invert(51%) sepia(85%) saturate(7319%) hue-rotate(257deg) brightness(94%) contrast(91%);
}

// #033F9F
@mixin blue-icon {
  filter: invert(13%) sepia(98%) saturate(2859%) hue-rotate(213deg) brightness(97%) contrast(98%);
}

// #8EB8FD
@mixin pastel-blue-icon {
  filter: invert(62%) sepia(72%) saturate(901%) hue-rotate(191deg) brightness(108%) contrast(98%);
}

// #175D1A
@mixin dark-green-icon {
  filter: invert(24%) sepia(16%) saturate(4940%) hue-rotate(88deg) brightness(91%) contrast(83%);
}
