<itc-auth-container>
  <div content class="mobile-stub__container">
    <div class="mobile-stub__header">
      <img width="77" height="15" ngSrc="assets/icons/login-logo.svg" />
      <div class="mobile-stub__header-text">
        {{ 'mobile-stub.header.title' | translate }}
      </div>
    </div>
    <div class="mobile-stub__content">
      <img width="74" height="56" ngSrc="assets/icons/kaseya-365/k365-logo.svg" />
      <div class="mobile-stub__content-title">
        {{ 'mobile-stub.title' | translate }}
      </div>
      <div class="mobile-stub__content-description">
        {{ 'mobile-stub.description' | translate }}
      </div>
      <div class="mobile-stub__content-list">
        <div class="mobile-stub__content-list-title">
          {{ 'mobile-stub.list.title' | translate }}
        </div>
        <div *ngFor="let key of pointKeys" class="mobile-stub__content-list-item">
          <img class="mobile-stub__content-list-item-icon" height="22" width="13" ngSrc="assets/icons/check.svg" />
          <span class="mobile-stub__content-list-item-text">{{ 'mobile-stub.list.' + key | translate }}</span>
        </div>
        <div class="mobile-stub__content-list-item-text">
          {{ 'mobile-stub.list.more' | translate }}
        </div>
      </div>
      <div *ngIf="isAuthorized" class="mobile-stub__button">
        <kds-large-button [buttonText]="'mobile-stub.log-out' | translate" (buttonClick)="logoutFromKaseyaOne()" />
      </div>
    </div>
  </div>
</itc-auth-container>
