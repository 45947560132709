import { Action, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { BillRunStatusLoaded, LoadBillRunStatus } from '@pages/payment-management/store/actions/bill-run-status.actions';
import { BillRunStatusService } from '@pages/payment-management/services/bill-run-status.service';

@State<void>({
  name: 'paymentManagementBillRunStatus',
})
@Injectable()
export class BillRunStatusState {
  private readonly billRunStatusService = inject(BillRunStatusService);

  @Action(LoadBillRunStatus)
  loadBillRunStatus(context: StateContext<void>): Observable<void> {
    return this.billRunStatusService.getBillRunStatus().pipe(
      mergeMap(isActiveBillRun => {
        return context.dispatch(new BillRunStatusLoaded(isActiveBillRun));
      })
    );
  }
}
