@use 'kaseya-styles/mixins/icon-color';

.navigation-link {
  &__item {
    display: flex;
    text-decoration: none;
    font-size: var(--font-size-small);
    line-height: 20px;
    color: var(--font-color-dark-blue);
    cursor: pointer;
    position: relative;
    padding: 5.5px 0 5.5px 46px;
    outline: none;

    &-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 12px);
    }

    &-icon {
      display: flex;
      justify-content: center;
      width: 16px;
      height: 14px;
      position: absolute;
      top: 8px;
      left: 20px;
    }

    &-icon-image {
      @include icon-color.dark-blue-icon;
    }

    &--active {
      background-color: #f0f1ff;

      &::before {
        content: '';
        width: 4px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #166efa;
      }
    }

    &--active &-text {
      font-family: var(--font-family-bold);
    }

    &:hover {
      color: var(--font-color-dark-blue);
    }

    &:not(&--active):hover {
      background-color: var(--font-color-light-pale-gray);
      text-decoration: none;
      color: var(--font-color-dark-blue);
    }
  }

  &__tooltip {
    padding-left: -10px !important;
  }
}
