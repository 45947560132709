import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { PaymentManagementActiveState } from '@layout/payment-management-active/store/states/payment-management-active.state';
import { selectSignal } from '@shared/utils/select-signal';
import { LoadPaymentManagementActive } from '@layout/payment-management-active/store/actions/payment-management-active.actions';

@Injectable({
  providedIn: 'root',
})
export class PaymentManagementActiveFacade {
  readonly isPaymentManagementActive = selectSignal(PaymentManagementActiveState.isPaymentManagementActive);

  constructor(private store: Store) {}

  loadPaymentManagementActive(): void {
    this.store.dispatch(new LoadPaymentManagementActive());
  }
}
