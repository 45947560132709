import { OrdersState } from '@pages/payment-management/store/state/orders.state';
import { PaymentSettingsState } from '@pages/payment-management/store/state/payment-settings.state';
import { ProductCategoriesState } from '@pages/payment-management/store/state/product-categories.state';
import { BillRunStatusState } from '@pages/payment-management/store/state/bill-run-status.state';
import { BankAccountState } from '@pages/payment-management/store/state/bank-account.state';
import { PaymentCardsState } from '@pages/payment-management/store/state/payment-cards.state';
import { PaymentMethodsState } from '@pages/payment-management/store/state/payment-methods.state';
import { PaymentCardState } from '@pages/payment-management/store/state/payment-card.state';
import { PaymentTypesState } from '@pages/payment-management/store/state/payment-types.state';

export const paymentManagementState = [
  OrdersState,
  PaymentSettingsState,
  ProductCategoriesState,
  PaymentCardsState,
  PaymentMethodsState,
  BillRunStatusState,
  BankAccountState,
  PaymentCardState,
  PaymentTypesState,
];
