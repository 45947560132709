.managers-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-top: 1px solid var(--font-color-light-dull-gray);
  box-shadow: 0 -4px 4px -1px rgb(0 0 0 / 7%);
  cursor: pointer;

  &__tooltip {
    margin-left: -10px !important;
  }
}
