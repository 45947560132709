import { DestroyRef, Directive, inject, input, TemplateRef, ViewContainerRef, OnChanges } from '@angular/core';
import { Country } from '@core/locations/models/country.enum';
import { UserInfoFacade } from '@core/user-info/facades/user-info.facade';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';
import { asNonUndefined } from '@shared/utils/as-non-undefined';
import { first } from 'rxjs';

@Directive({
  selector: '[itcCountryAccess]',
})
export class CountryAccessDirective implements OnChanges {
  readonly itcCountryAccess = input<Country[]>();

  private readonly userInfo$ = toObservable(this.userInfoFacade.userInfo).pipe(filterIsNotUndefined());
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private templateRef: TemplateRef<never>,
    private viewContainer: ViewContainerRef,
    private userInfoFacade: UserInfoFacade
  ) {}

  ngOnChanges(): void {
    if (!this.itcCountryAccess()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.checkCountryAccess();
  }

  private checkCountryAccess(): void {
    this.userInfo$.pipe(first(), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.userInfoFacade.isUserFromCountries(asNonUndefined(this.itcCountryAccess()))) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }

      this.viewContainer.clear();
    });
  }
}
