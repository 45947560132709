import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { LauncherModulesFacade } from '@layout/launcher/facades/launcher-modules.facade';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LauncherItemComponent } from '@layout/launcher/components/launcher-item/launcher-item.component';
import { DiscoverableLauncherItemComponent } from '@layout/launcher/components/discoverable-launcher-item/discoverable-launcher-item.component';
import { ProgressSpinnerComponent } from '@kaseya-design-components';

@Component({
  standalone: true,
  selector: 'itc-launcher-content',
  templateUrl: './launcher-content.component.html',
  styleUrls: ['./launcher-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, AsyncPipe, LauncherItemComponent, DiscoverableLauncherItemComponent, ProgressSpinnerComponent],
})
export class LauncherContentComponent implements AfterViewInit {
  @ViewChild('content') launcherContent: ElementRef<HTMLElement>;
  @Output() toggledWidget: EventEmitter<void> = new EventEmitter();

  readonly knowledgeBaseLink = 'https://help.one.kaseya.com/help/Default.htm#cshid=enable-login-with-k1-DPP';
  loading$ = this.launcherModulesFacade.loading$;
  modules$ = this.launcherModulesFacade.modules$;
  discoverableModules$ = this.launcherModulesFacade.discoverableModules$;
  launcherHeight: string;

  constructor(
    private launcherModulesFacade: LauncherModulesFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.launcherHeight = `calc(100vh - ${this.launcherContent.nativeElement.getBoundingClientRect().top}px)`;
    this.changeDetectorRef.detectChanges();
  }

  isInRightColumn(index: number): boolean {
    return index % 2 === 1;
  }

  toggleWidget(): void {
    this.toggledWidget.emit();
  }
}
