.navigation-tagged-link {
  itc-navigation-link .navigation-link__item {
    align-items: center;
    padding: 3.5px 2px 3.5px 46px;

    &-text {
      text-overflow: ellipsis;
      max-width: calc(100% - 26px);
      width: unset;
      margin-right: 8px;
    }
  }
}
