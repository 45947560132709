import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { fromEvent, Observable, retry, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from '@core/toast/toast.service';

@Injectable()
export class RetryWhenOnlineInterceptor implements HttpInterceptor {
  private onlineChanges$ = fromEvent(window, 'online').pipe(map(() => true));
  private toastService = inject(ToastService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry({
        delay: (httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.error instanceof ProgressEvent && !this.isOnline) {
            this.toastService.showErrorToast('general-errors.no-internet-connection');
            return this.onlineChanges$;
          }

          return throwError(() => httpErrorResponse);
        },
      })
    );
  }

  private get isOnline(): boolean {
    return navigator.onLine;
  }
}
