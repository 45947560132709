import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoadPaymentManagementActive } from '@layout/payment-management-active/store/actions/payment-management-active.actions';
import { PaymentManagementActiveService } from '@layout/payment-management-active/services/payment-management-active.service';

interface IPaymentManagementActiveStateModel {
  isPaymentManagementActive: boolean;
}
@State<IPaymentManagementActiveStateModel>({
  name: 'paymentManagementActive',
})
@Injectable()
export class PaymentManagementActiveState {
  constructor(private paymentManagementActiveService: PaymentManagementActiveService) {}

  @Selector()
  static isPaymentManagementActive(state: IPaymentManagementActiveStateModel): boolean {
    return state.isPaymentManagementActive;
  }

  @Action(LoadPaymentManagementActive)
  loadPaymentManagementActive(context: StateContext<IPaymentManagementActiveStateModel>): Observable<boolean> {
    return this.paymentManagementActiveService
      .getPaymentManagementActive()
      .pipe(tap(isPaymentManagementActive => context.patchState({ isPaymentManagementActive })));
  }
}
