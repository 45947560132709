import { ItCompleteAutomationsState } from '@pages/automation-center/store/states/it-complete-automations.state';
import { ThirdPartyIntegrationsState } from '@pages/automation-center/store/states/third-party-integrations.state';
import { KaseyaPlatformFeaturesState } from '@pages/automation-center/store/states/kaseya-platform-features.state';
import { AutomationsFilterState } from '@pages/automation-center/store/states/automations-filter.state';
import { AutomationsState } from '@pages/automation-center/store/states/automations.state';

export const automationCenterState = [
  AutomationsState,
  AutomationsFilterState,
  ItCompleteAutomationsState,
  ThirdPartyIntegrationsState,
  KaseyaPlatformFeaturesState,
];
