import { DestroyRef, Directive, inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { K365SetupGuidesAccessFacade } from '@layout/k365-setup-guides-access/facades/k365-setup-guides-access.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, first } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[itcK365SetupGuidesAccess]',
})
export class K365SetupGuidesAccessDirective implements OnInit {
  private readonly templateRef = inject(TemplateRef<never>);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly k365SetupGuidesAccessFacade = inject(K365SetupGuidesAccessFacade);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.k365SetupGuidesAccessFacade.setupGuidesAccess$
      .pipe(first(), filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.viewContainerRef.createEmbeddedView(this.templateRef));
  }
}
