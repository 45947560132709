import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@core/toast/toast.service';
import { showToast } from '@core/errors/models/context';

@Injectable()
export class RequestForbiddenInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === HttpStatusCode.Forbidden) {
          if (request.context.get(showToast)) {
            this.toastService.showErrorToast('general-errors.forbidden-error');
          }
        }

        return throwError(() => errorResponse);
      })
    );
  }
}
