import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { TokenService } from '@core/authorization/services/token.service';
import { StorageService } from '@core/storage/services/storage.service';
import { Router } from '@angular/router';
import { IAuthorizationData } from '@core/authorization/models/authorization-data.interface';
import { AuthorizationService } from '@core/authorization/services/authorization.service';
import { mfaTokenContext } from '@core/authorization/models/mfa-token-context';
import { SidePanelService } from '@design/side-panel/services/side-panel.service';
import { DialogService } from '@design/dialog/services/dialog.service';
import { KdsToastService } from '@kaseya-design-components';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<IAuthorizationData | undefined>(undefined);

  constructor(
    private authorizationService: AuthorizationService,
    private tokenService: TokenService,
    private storageService: StorageService,
    private dialogService: DialogService,
    private sidePanelService: SidePanelService,
    private kdsToastService: KdsToastService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(mfaTokenContext)) {
      return next.handle(request);
    }

    if (this.isRefreshing) {
      return this.retryRequest(request, next);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
          return this.tryAuthorizeWithRefreshToken(request, next);
        }
        return throwError(() => error);
      })
    );
  }

  private tryAuthorizeWithRefreshToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isRefreshing) {
      return this.retryRequest(request, next);
    }

    this.isRefreshing = true;
    this.refreshTokenSubject.next(undefined);
    const refreshToken = this.storageService.getAuthorizationData()?.refreshToken;

    if (!refreshToken) {
      this.logOut();
      return throwError(() => Error('Refresh token not found.'));
    }

    return this.tokenService.refreshToken(refreshToken).pipe(
      switchMap(authorizationResponse => {
        this.isRefreshing = false;
        this.storageService.setAuthorizationData(authorizationResponse);
        this.refreshTokenSubject.next(authorizationResponse);
        return next.handle(request);
      }),
      catchError(error => {
        this.logOut();
        return throwError(() => error);
      })
    );
  }

  private retryRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.refreshTokenSubject.pipe(
      filter(authorizationResponse => authorizationResponse !== undefined),
      take(1),
      switchMap(() => {
        return next.handle(request);
      })
    );
  }

  private logOut(): void {
    this.isRefreshing = false;
    this.authorizationService.logOut();
    this.dialogService.dismissAll();
    this.sidePanelService.closePanelIfExists();
    this.kdsToastService.closeAllToasts();
    this.goToLoginPage();
  }

  private goToLoginPage(): void {
    this.ngZone.run(() => {
      this.router.navigate(['login']);
    });
  }
}
