import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { filterIsNotUndefined } from '@shared/rxjs-operators/filter-is-not-undefined';
import { toObservable } from '@angular/core/rxjs-interop';
import { inject } from '@angular/core';
import { PaymentManagementActiveFacade } from '@layout/payment-management-active/facades/payment-management-active.facade';

export const canMatchPaymentManagementActive: CanMatchFn = () => {
  const paymentManagementActiveFacade = inject(PaymentManagementActiveFacade);
  const router = inject(Router);

  paymentManagementActiveFacade.loadPaymentManagementActive();

  return toObservable(paymentManagementActiveFacade.isPaymentManagementActive).pipe(
    filterIsNotUndefined(),
    map(isPaymentManagementActive => isPaymentManagementActive || router.createUrlTree(['access-denied']))
  );
};
