import { canAuthorizeUser } from '@core/authorization/guards/authorization.guard';
import { canMatchLoginContext } from '@core/authorization/guards/login-context.guard';
import { Country } from '@core/locations/models/country.enum';
import { canMatchCountry } from '@core/locations/guards/country.guard';
import { PageTitleTranslationKey } from '@core/page-title/models/page-title-translation-key.enum';
import { canMatchPermission } from '@core/permissions/guards/permission-access.guard';
import { Permission } from '@core/permissions/models/permission.enum';
import { AppRoutes } from '@core/route-data/models/app-routes';
import { PostLogoutWorkflow } from '@core/sso/models/post-logout-workflow.enum';
import { AuthViewComponent } from '@layout/auth-view/components/auth-view.component';
import { MainViewComponent } from '@layout/main-view/components/main-view.component';
import { canAccessKaseyaStore } from '@pages/kaseya-store/guards/can-access-kaseya-store.guard';
import { canMatchTenants } from '@pages/tenants/guards/tenants.guard';
import { provideStates } from '@ngxs/store';
import { professionalServicesState } from '@pages/professional-services/store';
import { canMatchFeature } from '@core/features/guards/features.guard.service';
import { Feature } from '@core/features/models/feature.enum';
import { organizationMappingState } from '@pages/organization-mapping/store';
import { canMatchSetupGuidesAccess } from '@layout/k365-setup-guides-access/guards/k365-setup-guides-access.guard';
import { firstTimeOnboardingStates } from '@pages/first-time-onboarding/store';
import {
  canMatchFirstTimeOnboardingAccess,
  canMatchFirstTimeOnboardingCompleted,
} from '@pages/first-time-onboarding/guards/first-time-onboarding.guard';
import { k365SetupGuidesStates } from '@pages/k365-setup-guides/store';
import { subscriptionsState } from '@pages/subscriptions/store';
import { subscriptionsRoutes } from '@pages/subscriptions/subscriptions-routes.';
import { canMatchPartnerProgramActive } from '@pages/partner-program/guards/partner-program-acitve.guard';
import { provideTranslationConfig } from '@core/translation/models/translation-config';
import { TranslationFeature } from '@core/translation/models/translation-feature.enum';
import { paymentManagementRoutes } from '@pages/payment-management/payment-management-routes.';
import { paymentManagementState } from '@pages/payment-management/store';
import { supportTicketsState } from '@pages/support/store';
import { canMatchPaymentManagementActive } from '@layout/payment-management-active/guards/payment-management-active.guard';
import { automationCenterState } from '@pages/automation-center/store';

export const routes: AppRoutes = [
  {
    path: '',
    component: MainViewComponent,
    canActivate: [canAuthorizeUser, canMatchFirstTimeOnboardingCompleted],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadChildren: () => import('@pages/home/home.module').then(({ HomeModule }) => HomeModule),
        data: {
          disableMobileVersion: true,
        },
        providers: [provideTranslationConfig(TranslationFeature.Home)],
      },
      {
        path: 'subscriptions',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadComponent: () =>
          import('@pages/subscriptions/components/subscriptions-page/subscriptions-page.component').then(
            ({ SubscriptionsPageComponent }) => SubscriptionsPageComponent
          ),
        data: {
          title: PageTitleTranslationKey.Subscriptions,
          disableMobileVersion: true,
          permission: Permission.BillingAndSubscriptions,
        },
        children: subscriptionsRoutes,
        providers: [provideStates(subscriptionsState), provideTranslationConfig(TranslationFeature.Subscriptions)],
      },
      {
        path: 'payment-management',
        canMatch: [canAuthorizeUser, canMatchPermission, canMatchFeature, canMatchPaymentManagementActive],
        loadComponent: () =>
          import('@pages/payment-management/components/payment-management-page/payment-management-page.component').then(
            ({ PaymentManagementPageComponent }) => PaymentManagementPageComponent
          ),
        data: {
          title: PageTitleTranslationKey.PaymentManagement,
          disableMobileVersion: true,
          permission: Permission.PaymentManagementView,
          feature: Feature.PaymentManagement,
          fitsScreenWidth: true,
        },
        children: paymentManagementRoutes,
        providers: [provideStates(paymentManagementState), provideTranslationConfig(TranslationFeature.PaymentManagement)],
      },
      {
        path: 'organization-mapping',
        canMatch: [canAuthorizeUser, canMatchPermission, canMatchFeature],
        loadComponent: () =>
          import('@pages/organization-mapping/components/organization-mapping-page/organization-mapping-page.component').then(
            ({ OrganizationMappingPageComponent }) => OrganizationMappingPageComponent
          ),
        data: {
          title: PageTitleTranslationKey.OrganizationMapping,
          disableMobileVersion: true,
          permission: Permission.OrganizationMappingsView,
          feature: Feature.OrganizationMappings,
          fitsScreenWidth: true,
        },
        providers: [provideStates(organizationMappingState), provideTranslationConfig(TranslationFeature.OrganizationMapping)],
      },
      {
        path: 'kaseya-365-setup-guide',
        canMatch: [canAuthorizeUser, canMatchPermission, canMatchFeature, canMatchSetupGuidesAccess],
        loadComponent: () =>
          import('@pages/k365-setup-guides/components/k365-setup-guides-page/k365-setup-guides-page.component').then(
            ({ K365SetupGuidesPageComponent }) => K365SetupGuidesPageComponent
          ),
        data: {
          title: PageTitleTranslationKey.Kaseya365SetupGuide,
          disableMobileVersion: true,
          permission: Permission.OnboardingSubscriptionsView,
          feature: Feature.Kaseya365SetupGuide,
        },
        providers: [provideStates(k365SetupGuidesStates), provideTranslationConfig(TranslationFeature.Kaseya365SetupGuide)],
      },
      {
        path: 'cooper',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadChildren: () => import('@pages/insights/insights.module').then(({ InsightsModule }) => InsightsModule),
        data: {
          title: PageTitleTranslationKey.Cooper,
          disableMobileVersion: true,
        },
      },
      {
        path: 'support',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadComponent: () => import('@pages/support/components/support/support.component').then(({ SupportComponent }) => SupportComponent),
        data: {
          title: PageTitleTranslationKey.Support,
          disableMobileVersion: true,
        },
        providers: [provideStates(supportTicketsState), provideTranslationConfig(TranslationFeature.Support)],
      },
      {
        path: 'admin-settings',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadChildren: () => import('@pages/admin-settings/admin-settings.module').then(({ AdminSettingsModule }) => AdminSettingsModule),
        data: {
          permission: Permission.UserManagementView,
          title: PageTitleTranslationKey.AdminSettings,
          disableMobileVersion: true,
        },
        providers: [provideTranslationConfig(TranslationFeature.AdminSettings)],
      },
      {
        path: 'automation-center',
        canMatch: [canAuthorizeUser, canMatchPermission, canMatchFeature],
        loadComponent: () =>
          import('@pages/automation-center/components/automation-center-page/automation-center-page.component').then(
            ({ AutomationCenterPageComponent }) => AutomationCenterPageComponent
          ),
        data: {
          title: PageTitleTranslationKey.AutomationCenter,
          feature: Feature.AutomationCenter,
          fitsScreenWidth: true,
          permission: Permission.AutomationCenterView,
        },
        providers: [provideTranslationConfig(TranslationFeature.AutomationCenter), provideStates(automationCenterState)],
      },
      {
        path: 'executive-reports',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadChildren: () =>
          import('@pages/executive-reports/executive-reports.routes').then(({ routes: executiveReportsRoutes }) => executiveReportsRoutes),
        data: {
          permission: Permission.ExecutiveReports,
          title: PageTitleTranslationKey.ExecutiveReports,
          disableMobileVersion: true,
        },
        providers: [provideTranslationConfig(TranslationFeature.ExecutiveReports)],
      },
      {
        path: 'talent-finder',
        canMatch: [canAuthorizeUser],
        loadChildren: () => import('@pages/talent-finder/talent-finder.module').then(({ TalentFinderModule }) => TalentFinderModule),
        data: {
          title: PageTitleTranslationKey.TalentFinder,
          disableMobileVersion: true,
        },
        providers: [provideTranslationConfig(TranslationFeature.TalentFinder)],
      },
      {
        path: 'my-settings',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadChildren: () => import('@pages/my-settings/my-settings.module').then(({ MySettingsModule }) => MySettingsModule),
        data: {
          title: PageTitleTranslationKey.MySettings,
          disableMobileVersion: true,
        },
      },
      {
        path: 'cyber-insurance',
        canMatch: [canAuthorizeUser, canMatchPermission, canMatchCountry],
        loadChildren: () =>
          import('@pages/cyber-insurance/cyber-insurance.module').then(({ CyberInsuranceModule }) => CyberInsuranceModule),
        data: {
          title: PageTitleTranslationKey.CyberInsurance,
          permission: Permission.CyberInsurance,
          accessibleCountries: [Country.US, Country.AU, Country.NZ],
          disableMobileVersion: true,
        },
        providers: [provideTranslationConfig(TranslationFeature.CyberInsurance)],
      },
      {
        path: 'partner-program',
        canMatch: [canAuthorizeUser, canMatchPartnerProgramActive],
        loadChildren: () =>
          import('@pages/partner-program/partner-program.module').then(({ PartnerProgramModule }) => PartnerProgramModule),
        data: {
          title: PageTitleTranslationKey.PartnerProgram,
          disableMobileVersion: true,
        },
        providers: [provideTranslationConfig(TranslationFeature.PartnerProgram)],
      },
      {
        path: 'professional-services',
        canMatch: [canAuthorizeUser, canMatchPermission],
        loadComponent: () =>
          import('@pages/professional-services/components/professional-services-page/professional-services-page.component').then(
            ({ ProfessionalServicesPageComponent }) => ProfessionalServicesPageComponent
          ),
        data: {
          title: PageTitleTranslationKey.ProfessionalServices,
          disableMobileVersion: true,
          permission: Permission.ProfessionalServicesDashboard,
        },
        providers: [provideStates(professionalServicesState), provideTranslationConfig(TranslationFeature.ProfessionalServices)],
      },
    ],
  },
  {
    path: '',
    component: AuthViewComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('@pages/login/login.module').then(({ LoginModule }) => LoginModule),
        data: {
          title: PageTitleTranslationKey.Login,
        },
      },
      {
        path: 'cannot-login',
        loadChildren: () =>
          import('@pages/login-recovery-options/login-recovery-options-list.module').then(
            ({ LoginRecoveryOptionsListModule }) => LoginRecoveryOptionsListModule
          ),
        data: {
          title: PageTitleTranslationKey.CannotLogin,
        },
      },
      {
        path: 'product-access-denied',
        loadChildren: () =>
          import('@pages/product-access-denied/product-access-denied.module').then(
            ({ ProductAccessDeniedModule }) => ProductAccessDeniedModule
          ),
        data: {
          title: PageTitleTranslationKey.ProductAccessDenied,
          disableMobileVersion: true,
        },
      },
      {
        path: 'connect',
        loadChildren: () => import('@pages/login/login.module').then(({ LoginModule }) => LoginModule),
        data: {
          title: PageTitleTranslationKey.Login,
        },
      },
      {
        path: 'push-mfa-verification',
        canMatch: [canMatchLoginContext],
        loadChildren: () =>
          import('@pages/push-mfa-verification/push-mfa-verification.module').then(
            ({ PushMfaVerificationModule }) => PushMfaVerificationModule
          ),
        data: {
          title: PageTitleTranslationKey.PushMfa,
        },
      },
      {
        path: 'connect/tenants',
        canMatch: [canMatchTenants],
        loadChildren: () => import('@pages/tenants/tenants.module').then(({ TenantsModule }) => TenantsModule),
        data: {
          title: PageTitleTranslationKey.Tenants,
        },
      },
      {
        path: 'logout',
        loadChildren: () => import('@pages/logout/logout.module').then(({ LogoutModule }) => LogoutModule),
      },
      {
        path: 'connect/logout',
        loadChildren: () => import('@pages/logout/logout.module').then(({ LogoutModule }) => LogoutModule),
        data: {
          workflow: PostLogoutWorkflow.OpenIdConnect,
        },
      },
      {
        path: 'confirm-email/:token',
        loadChildren: () =>
          import('@pages/email-confirmation/email-confirmation.module').then(({ EmailConfirmationModule }) => EmailConfirmationModule),
        data: {
          disableMobileVersion: true,
        },
      },
      {
        path: ':token/reset-password',
        loadChildren: () => import('@pages/reset-password/reset-password.module').then(({ ResetPasswordModule }) => ResetPasswordModule),
        data: {
          title: PageTitleTranslationKey.ResetPassword,
          disableMobileVersion: true,
        },
      },
      {
        path: 'user/sign-up/:token',
        loadChildren: () => import('@pages/set-password/set-password.module').then(({ SetPasswordModule }) => SetPasswordModule),
        data: {
          title: PageTitleTranslationKey.SetPassword,
          disableMobileVersion: true,
        },
      },
      {
        path: 'impersonate',
        loadChildren: () => import('@pages/impersonate/impersonate.module').then(({ ImpersonateModule }) => ImpersonateModule),
        data: {
          title: PageTitleTranslationKey.Impersonate,
          disableMobileVersion: true,
        },
      },
      {
        path: 'third-party-sso',
        loadChildren: () =>
          import('@pages/third-party-sso-authentication/third-party-sso-authentication.module').then(
            ({ ThirdPartySsoAuthenticationModule }) => ThirdPartySsoAuthenticationModule
          ),
        data: {
          title: PageTitleTranslationKey.ThirdPartySso,
        },
      },
    ],
  },
  {
    path: 'totp-mfa-verification',
    canMatch: [canMatchLoginContext],
    loadChildren: () =>
      import('@pages/totp-mfa-verification/totp-mfa-verification.module').then(
        ({ TotpMfaVerificationModule }) => TotpMfaVerificationModule
      ),
    data: {
      title: PageTitleTranslationKey.TotpMfa,
    },
  },
  {
    path: 'totp-mfa-enrollment',
    canMatch: [canMatchLoginContext],
    loadChildren: () =>
      import('@pages/verification-setup/verification-setup.module').then(({ VerificationSetupModule }) => VerificationSetupModule),
    data: {
      title: PageTitleTranslationKey.TotpMfaEnrollment,
    },
  },
  {
    path: 'onboarding',
    canMatch: [canAuthorizeUser, canMatchPermission, canMatchFirstTimeOnboardingAccess],
    loadComponent: () =>
      import('@pages/first-time-onboarding/components/first-time-onboarding-page/first-time-onboarding-page.component').then(
        ({ FirstTimeOnboardingPageComponent }) => FirstTimeOnboardingPageComponent
      ),
    data: {
      title: PageTitleTranslationKey.FirstTimeOnboarding,
      permission: Permission.OnboardingFirstMasterUserView,
      disableMobileVersion: true,
    },
    providers: [provideStates(firstTimeOnboardingStates), provideTranslationConfig(TranslationFeature.Onboarding)],
  },
  {
    path: 'pro-services',
    loadChildren: () => import('@pages/registration/registration.module').then(({ RegistrationModule }) => RegistrationModule),
    data: {
      title: PageTitleTranslationKey.Registration,
    },
  },
  {
    path: 'helpdesk',
    loadChildren: () =>
      import('@pages/support-tickets-authentication/support-tickets-authentication.module').then(
        ({ SupportTicketsAuthenticationModule }) => SupportTicketsAuthenticationModule
      ),
  },
  {
    path: 'store',
    canActivate: [canAccessKaseyaStore],
    component: AuthViewComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@pages/kaseya-store/kaseya-store.module').then(({ KaseyaStoreModule }) => KaseyaStoreModule),
        data: {
          title: PageTitleTranslationKey.KaseyaStore,
          disableMobileVersion: true,
        },
      },
    ],
  },
  {
    path: 'access-denied',
    loadChildren: () => import('@pages/access-denied/access-denied.module').then(({ AccessDeniedModule }) => AccessDeniedModule),
    data: {
      title: PageTitleTranslationKey.AccessDenied,
    },
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
